/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'react-bootstrap';
import Link from 'next/link';
import styles from './button.module.css';

// eslint-disable-next-line import/prefer-default-export
export const ButtonLink = ({ href = '', as = '', text = '', className = '', style = {} }) => (
  <Link href={href} as={as} passHref>
    <a>
      <Button variant="primary" className={`${styles.buttondefault} ${className}`} style={style}>
        {text}
      </Button>
    </a>
  </Link>
);

export const ButtonClick = ({ onClick = () => null, text = '', className = '', style = {} }) => (
  <Button variant="primary" className={`${styles.buttondefault} ${className}`} style={style} onClick={onClick}>
    {text}
  </Button>
);
