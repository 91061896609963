import React, { useState } from 'react';
import axios from 'axios';

import Head from 'next/head';
import { toast } from 'react-toastify';
import Router from 'next/router';
import Layout from '@components/layout';
import catData from '@utils/data/category';
import { isSafari, isIOS } from 'react-device-detect';
import HomeWhat from '@components/specific/home/HomeWhat';
import HomeDiscounts from '@components/specific/home/HomeDiscounts';
import HomeFavoriteBrands from '@components/specific/home/HomeFavoriteBrands';
import HomeLead from '@components/specific/home/HomeLead';

const brands = [
  {
    name: 'KFC',
    img: 'https://static.disqonin.com/uploads/brandimg/kfc.jpg',
    url: '/kfc'
  },{
    name: 'McDonalds',
    img: 'https://static.disqonin.com/uploads/brandimg/mcdonalds.jpg',
    url: '/mcdonalds'
  },{
    name: 'ShopeePay',
    img: 'https://static.disqonin.com/uploads/brandimg/shopeepay.jpg',
    url: '/shopeepay'
  },{
    name: "Roti'O",
    img: 'https://static.disqonin.com/uploads/brandimg/rotio.jpg',
    url: '/rotio'
  },{
    name: "Indomaret",
    img: 'https://static.disqonin.com/uploads/brandimg/indomaret.jpg',
    url: '/indomaret'
  },{
    name: "Alfamart",
    img: 'https://static.disqonin.com/uploads/brandimg/alfamart.jpg',
    url: '/alfamart'
  },{
    name: "Hypermart",
    img: 'https://static.disqonin.com/uploads/brandimg/hypermart.jpg',
    url: '/hypermart'
  },{
    name: "Giant",
    img: 'https://static.disqonin.com/uploads/brandimg/giant.jpg',
    url: '/giant'
  },{
    name: "Pizza Hut",
    img: 'https://static.disqonin.com/uploads/brandimg/pizza-hut.jpg',
    url: '/pizza-hut'
  },{
    name: "Hokben",
    img: 'https://static.disqonin.com/uploads/brandimg/hokben.jpg',
    url: '/hokkben'
  },{
    name: "J.Co",
    img: 'https://static.disqonin.com/uploads/brandimg/jco.jpg',
    url: '/jco'
  },{
    name: "Richeese",
    img: 'https://static.disqonin.com/uploads/brandimg/richeese.jpg',
    url: '/richeese'
  }
]

function App(props) {
  const { shows = [] } = props;
  const [contactForm, setcontactForm] = useState('');

  // eslint-disable-next-line no-unused-vars
  const notify = () =>
    toast.warn('Untuk saat ini hanya tersedia di aplikasi mobile', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const goToContact = () => {
    if (contactForm.length < 1) {
      toast.warn('Mohon masukkan teks disamping', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      Router.push({ pathname: '/contact', query: { message: contactForm } });
    }
  };

  const categoryData = catData.slice(0, 5) || [];
  const isShowPng = isSafari || isIOS;

  return (
    <>
      <Head>
        <title>Disqonin - Platform Diskon dan Promo | Diskon | Cashback | Katalog Promosi | Aplikasi</title>
        <meta
          name="description"
          content="Platform Diskon dan Promosi se Indonesia, Promo Diskon Makanan Minuman Fashion Travel Hobi Bank E-Money dan Lainnya, Tersedia aplikasi mobile"
        />
        <link rel="canonical" href="https://disqonin.com" />
      </Head>
      <div style={{ backgroundColor: '#F7F7F7' }}>
        <Layout hideMenuBar>
          <HomeLead isShowPng={isShowPng} />
          <HomeWhat isShowPng={isShowPng} />  
          <HomeDiscounts discounts={shows} />      
          <HomeFavoriteBrands brands={brands} />
        </Layout>
      </div>
    </>
  );
}

App.getInitialProps = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'https://api.disqonin.com/discount/v3/latest',
      params: {
        limit: 12,
      },
    });
    const { data } = res?.data || {};
    return {
        shows: data
    };
  } catch (err) {
    return {
      shows: []
    }
  }
}

export default App;
