/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import styles from './card.module.css';
import functionApp from '@utils/service/functionApp';

// eslint-disable-next-line import/prefer-default-export

export const CardHomeExplain = ({ img = '/static/img/home/follow.png', title = '', desc = '' }) => (
  <div className="col-md-3 col-sm-12">
    <div className={styles.cardexplain}>
      <img src={img} alt="Follow brand diskon" className="m-auto d-flex pt-1" />
      <p className="font-weight-bold text-small-2 text-center mt-3 mb-1">{title}</p>
      <p className="text-small-3 text-center">{desc}</p>
    </div>
  </div>
);

export const CardHomeExplainSvg = ({ svg = <div />, title = '', desc = '' }) => (
  <div className="col-md-3 col-sm-12">
    <div className={styles.cardexplain}>
      <div className="d-flex justify-content-center">{svg}</div>
      <p className="font-weight-bold text-small-2 text-center mt-3 mb-1">{title}</p>
      <p className="text-small-3 text-center">{desc}</p>
    </div>
  </div>
);

export const CardHomeExplainSquare = ({ as = '', img = '/static/img/home/follow.png', title = '', href = '', onClick = false }) => {
  if (!onClick) {
    return (
      <Link as={as} href={href} passHref>
        <a>
          <div className={styles.cardexplainsquare}>
            <img src={img} alt="Follow brand diskon" className="m-auto d-flex pt-2" />
            <p className="font-weight-bold text-small-2 text-center mt-3 mb-1 pl-1 pr-1 text-dark">{title}</p>
          </div>
        </a>
      </Link>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <a onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className={styles.cardexplainsquare}>
        <img src={img} alt="Follow brand diskon" className="m-auto d-flex pt-2" />
        <p className="font-weight-bold text-small-2 text-center mt-3 mb-1 pl-1 pr-1 text-dark">{title}</p>
      </div>
    </a>
  );
};

export const CardSideCategory = ({ img = '/static/img/home/follow.png', title = '', href = '' }) => (
  <Link href={href}>
    <a aria-label={title}>
      <LazyLoad>
        <div className={styles.cardcategoryside}>
          <img src={img} alt="Follow brand diskon" className="m-auto d-flex pt-2" />
          <p className="font-weight-bold text-small-1 text-center mt-3 mb-1 pl-1 pr-1 text-dark">{title}</p>
        </div>
      </LazyLoad>
    </a>
  </Link>
);

export const CardDiscount = ({ url, name, img = '' }) => {
  const imgFixed = functionApp.filterImageUrl(img);
  return (
    <div className="min-w-47vw max-w-47vw lg:min-w-0 lg:w-20vw card-container pl-2 pr-2">
      <Link href={`/discount/${url}`}>
        <a style={{ textDecoration: 'none' }}>
          <div className={`${styles.carddiscount}`}>
            <img className={`card-img-top ${styles.carddiscountimg}`} alt={name} object-fit="contain" title={name} src={`${imgFixed}`} />
            <div className={`card-body-other mt-2`}>
              <h3 className={`card-title text-dark text-left pt-1 align-middle h6 ${styles.truncateOverflow}`}>{name}</h3>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};
