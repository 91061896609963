import Link from 'next/link';
import React, { useState } from 'react';

function HomeWhat ({ isShowPng = false }) {
    const [showNotif, setShowNotif] = useState(false)

    const showNotifTimeout = () => {
      setShowNotif(true)
      setTimeout(() => {
        setShowNotif(false)
      }, 5000);
    }
  
    const json = [
      {
        img: '/static/svg/catalog.svg',
        href: '/category/katalog',
        title: 'Katalog',
        element: (
          <>
            Dapatkan katalog terhemat<br />
            dari Giant, Superindo, Hypermart,<br />
            Alfamart, dll lebih mudah disini!
          </>
        )
      },
      {
        img: '/static/svg/discount.svg',
        href: '/discount',
        title: 'Diskon & Promo',
        element: (
          <>
            Diskon dan promosi makanan,<br />
            fashion, travel, E-Commerce dan<br />
            banyak lagi, semuanya ada disini!<br />
          </>
        )
      },
      {
        img: '/static/svg/coupon.svg',
        href: '',
        onclick: () => showNotifTimeout(),
        title: 'Kupon Mart',
        element: (
          <>
            Perbandingan harga kupon<br />
            dari berbagai sumber biar tau<br />
            beli dimana yg termurah!<br />
          </>
        )
      }
    ]

    return (
      <section className="mt-5 mb-3 container z-10">
          <h2 className={`text-main-greyText text-center text-2xl font-bold py-20`}>
            Ada apa aja di 
            <img
              className="inline-block"
              style={{ width: '150px' }}
              alt="disqonin"
              src={`/static/img/logo-black-left${isShowPng ? '.png' : '.webp'}`}
            />
            ?
          </h2>
          <div className="flex flex-col lg:flex-row justify-center relative">
            {json.map((a, i) => (
              <div className="px-5 justify-center mb-5 lg:mb-0" key={i}>
                <img className="w-40 h-32 mx-auto" src={a.img} />
                <div className="flex justify-center items-center mt-8">
                  <h3 className="text-lg py-1 px-2 text-center border-4 rounded border-black">
                    {a.title}
                  </h3>
                </div>
                <p className="text-center font-bold italic text-black my-3">
                  {a.element}
                </p>
                {i === json.length-1 && (
                  <div
                    onClick={a.onclick}
                    className={`bg-red-600 w-80vw md:w-20vw h-auto p-2 text-center text-white rounded-lg absolute transition duration-500 cursor-pointer ${!showNotif ? 'opacity-0' : 'opacity-100'}`}
                  >
                    Fitur ini sementara hanya tersedia di aplikasi
                  </div>
                )}
                {a.href ? (
                  <Link href={`${a.href}`} key={a.href} passHref>
                    <a>
                      <p className="text-center underline font-bold text-blue-900">
                        Lihat Diskon & Promosi  ➜
                      </p>
                    </a>
                  </Link>
                  ):(
                    <p className="text-center underline font-bold text-blue-900 cursor-pointer" onClick={a.onclick}>
                      Lihat Diskon & Promosi  ➜
                    </p>
                  )}
              </div>
            ))}
          </div>
          
					<div
						style={{
							position: 'absolute',
							left: 0,
						}}
						className="d-block aaasss"
					>
						<img
							src="/static/svg/promo-bg-2.svg"
							alt="fitur diskon dan promo"
							className="position-absolute d-lg-block"
							style={{
								width: 400,
							}}
						/>
					</div>
      </section>
    )
}

export default HomeWhat