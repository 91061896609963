import Link from 'next/link';
import React from 'react';

function HomeFavoriteBrands ({ brands = [] }) {
	return (
		<section className="mt-5 mb-3" id="form">
			<div className="mask rgba-gradient align-items-center">
				{brands.length > 0 && (
					<div style={{ maxWidth: '95%', margin: 'auto' }}>
						<div style={{ marginTop: 20 }}>
							<h2 className="color-primary text-center text-base font-bold">Brands</h2>
							<h3
								className="title-overview wow fadeInUp text-white text-dark text-center text-2xl font-bold my-3"
							>
								Brand favorit user bulan ini
							</h3>
							<p className="subtitle-overview wow fadeInUp mb-4 text-white text-dark text-center">
								Dibawah ini adalah brand favorit dari Disqoners
								<br />
								jangan lupa buat download aplikasi mobile disqonin
							</p>
						</div>
						<div className="container">
							<div className="row mt-5">
								<div className="row justify-center mx-auto">
									{brands.map(a => (
										<Link href="/brand/[id]" as={`/brand${a.url}`} key={a.url}>
											<a className="col-3 justify-content-center d-flex flex-column align-items-center text-dark font-weight-bold mt-3">
												<img src={a.img} width="50" height="50" alt={`Promo ${a.name}`} />
												<p className="mt-2">{a.name}</p>
											</a>
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</section>
	)
}

export default HomeFavoriteBrands
