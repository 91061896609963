/* eslint-disable use-isnan */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
const functionApp = {
  filter(str) {
    str = this.cutWords(str);
    str = str
      .replace('?', '')
      .replace(/\.| |"|“|'|”|’|`|#|!|\,|\*|\:|\+/g, '-')
      .replace(/%|&/g, '')
      .replace(/--|--|––/g, '-')
      .replace(/–––|--|--|---|-–-|––/g, '-')
      .toLowerCase();
    if (str.endsWith('–') || str.endsWith('-')) {
      return str.replace(/.$/, '');
    }
    return str;
  },
  cutWords(yourString, length = 60) {
    const maxLength = length; // maximum number of characters to extract

    // trim the string to the maximum length
    let trimmedString = yourString.substr(0, maxLength);
    // re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));

    return trimmedString;
  },
  onlyFilter(str) {
    str = str
      .replace('?', '')
      .replace(/\.| |"|“|'|”|’|`|#|!|\,|\*|\:|\+/g, '-')
      .replace(/%|&/g, '')
      .replace(/--|--|––/g, '-')
      .replace(/–––|--|--|---|-–-|––/g, '-')
      .toLowerCase();
    if (str.endsWith('–') || str.endsWith('-')) {
      return str.replace(/.$/, '');
    }
    return str;
  },
  extendObj(obj, src) {
    for (const key in src) {
      if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
  },
  hasUpperCase(str) {
    return /[A-Z]/.test(str);
  },

  removeDuplicates(array, key) {
    const lookup = {};
    return array.filter((obj) => !lookup[obj[key]] && (lookup[obj[key]] = true));
  },

  removeDuplicatesTwo(arr1, arr2) {
    const arr1Id = new Set(arr1.map(({ id }) => id));
    return [...arr2.filter(({ id }) => !arr1Id.has(id))];
  },

  groupByJavascript(input) {
    const result = [];
    console.log(input);
    input.map((data, i) => {
      let found = false;
      let name = null;
      let count = 0;
      for (let j = 0; j < result.length; j++) {
        if (result[j].id === data.id) {
          found = true;
          result[j].userId += data.userId;
          name = result[j].name ? result[j].name : name;
          count += result[j].votecnt;
          console.log('masuk', result);
          break;
        }
      }
      if (!found) {
        data.name = name;
        data.count = count;
        result.push(data);
      }
    });
    // The result array is your desired result
    return result;
  },
  filterImageUrl(url = '') {
    let imgUrl = url?.replace('https://static.disqonin.com/', '');
    imgUrl = imgUrl?.replace('https://disqonin-sites.s3.ap-southeast-1.amazonaws.com/', '');
    if (!imgUrl) return imgUrl;
    if (imgUrl.startsWith('https://' || 'http://') && imgUrl.includes('duply.co')) {
      return imgUrl;
    }
    const urlSplitted = imgUrl.split('/') || [];
    const imgFixed = `/api/image/${imgUrl}`;
    if (urlSplitted[2]) {
      const date = urlSplitted[2];
      const splitted = date.split('-') || [];
      const month = parseInt(splitted[0], 10);
      const year = parseInt(splitted[1], 10);
      if (month && year && month !== NaN && year !== NaN) {
        if ((month < 4 && year === 2020) || year < 2020) {
          return url;
        }
      }
    }
    return imgFixed;
  },
};

export default functionApp;
