import { ButtonLink } from '@components/atoms/button';
import { CardDiscount } from '@components/atoms/card';
import React from 'react';

function HomeDiscounts ({ discounts = [] }) {
	return (
		<section className="mt-5 mb-3" id="form">
			<div className="mask rgba-gradient align-items-center mt-32">
				{discounts.length > 0 && (
					<div style={{ maxWidth: '95%', margin: 'auto' }}>
						<div style={{ marginTop: 20 }}>
							<h2 className="color-primary text-center text-base font-bold">Promo & diskon</h2>
							<h3
								className="title-overview wow fadeInUp text-white text-dark text-center text-2xl font-bold my-3"
							>
								Jelajahi diskon
							</h3>
							<p className="subtitle-overview wow fadeInUp mb-4 text-white text-dark text-center">
								Selamat menikmati platform Diskon terlengkap,
								<br />
								termudah dan terupdate kami!
							</p>
						</div>
						<div className="container mt-10 w-screen -ml-3 lg:ml-0 lg:w-auto">
							<div className="row justify-center mx-auto" style={{ marginTop: 20 }}>
								{discounts.map((data, index) => (
									<CardDiscount name={data.name} url={data.url} img={data.img} key={index} />
								))}
							</div>
						</div>
						<div className="d-flex justify-content-center mt-2">
							<ButtonLink text="Lihat semua" href="/discount" />
						</div>
					</div>
				)}
			</div>
		</section>
	)
}

export default HomeDiscounts
