const catData = [
  {
    name: 'Katalog',
    url: '/category/katalog',
    slug: 'katalog',
    img: '/static/img/home/category/katalog.png',
    id: 11,
  },
  {
    name: 'Makanan & Minuman',
    url: '/category/makanan',
    slug: 'makanan',
    img: '/static/img/home/category/promo-makanan.png',
    id: 1,
  },
  {
    name: 'Fashion',
    url: '/category/fashion',
    slug: 'fashion',
    img: '/static/img/home/category/diskon-fashion.png',
    id: 2,
  },
  {
    name: 'Hiburan',
    url: '/category/hiburan',
    slug: 'hiburan',
    img: '/static/img/home/category/promo-hiburan.png',
    id: 3,
  },
  {
    name: 'Travel',
    url: '/category/travel',
    slug: 'travel',
    img: '/static/img/home/category/promo-travel.png',
    id: 4,
  },
  {
    name: 'Elektronik',
    url: '/category/elektronik',
    slug: 'elektronik',
    img: '/static/img/home/category/promo-diskon-elektronik.png',
    id: 5,
  },
  {
    name: 'Kecantikan',
    url: '/category/kecantikan',
    slug: 'kecantikan',
    img: '/static/img/home/category/promo-kecantikan.png',
    id: 6,
  },
  {
    name: 'Otomotif',
    url: '/category/otomotif',
    slug: 'otomotif',
    img: '/static/img/home/category/promo-diskon-otomotif.png',
    id: 7,
  },
  {
    name: 'Kesehatan',
    url: '/category/kesehatan',
    slug: 'kesehatan',
    img: '/static/img/home/category/promo-kesehatan-gaya-hidup.png',
    id: 8,
  },
  {
    name: 'Peliharaan',
    url: '/category/peliharaan',
    slug: 'peliharaan',
    img: '/static/img/home/category/promo-diskon-peliharaan.png',
    id: 9,
  },
  {
    name: 'Layanan',
    url: '/category/layanan',
    slug: 'layanan',
    img: '/static/img/home/category/promo-diskon-layanan.png',
    id: 10,
  },
  {
    name: 'Online',
    url: '/category/online',
    slug: 'online',
    img: '/static/img/home/category/promo-online.png',
    id: 12,
  },
  {
    name: 'Transportasi',
    url: '/category/transportasi',
    slug: 'transportasi',
    img: '/static/img/home/category/transportasi.png',
    id: 13,
  },
  {
    name: 'Bank & E-money',
    url: '/category/bank-e-money',
    slug: 'bank-e-money',
    img: '/static/img/home/category/bank-dan-e-money.png',
    id: 14,
  },
  {
    name: 'Marketplace',
    url: '/category/marketplace',
    slug: 'marketplace',
    img: '/static/img/home/category/promo-diskon-katalog.png',
    id: 15,
  },
  {
    name: 'Hobi',
    url: '/category/hobi',
    slug: 'hobi',
    img: '/static/img/home/category/promo-diskon-hobi.png',
    id: 16,
  },
];

export default catData;
