import { SearchbarWithButton } from '@components/molecules/Searchbar';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

function HomeLead ({ isShowPng = false,  }) {
	const router = useRouter()
	const [searchKeyword, setSearchKeyword] = useState('')

	const onSearch = async () => {
		router.push({ pathname: '/search/[keyword]', query: { keyword: searchKeyword } });
	}

    return (
		<section className="lead">
			<div className="mask rgba-gradient align-items-center relative">
				<div
					className="bg-no-repeat bg-contain bg-center absolute pt-12 -mt-5 -left-12 top-0"
					style={{
						backgroundImage: 'url(/static/svg/promo-bg-1.svg)',
						width: '50vw',
						height: '60vw',
						transform: 'scale(1, -1) rotate(180deg)',
						filter: 'blur(10px)'
					}}
				/>
				<div
					className="wow fadeInRight absolute left-10 top-32"
					data-wow-delay="0.3s"
					style={{
						filter: 'blur(10px)'
					}}
				>
					<LazyLoad height={100}>
						<img
							style={{ width: '30vw', justifyContent: 'right' }}
							src={`/static/img/home/aplikasi-diskon-dan-promo${isShowPng ? '.png' : '.webp'}`}
							alt="dsq"
						/>
					</LazyLoad>
				</div>
				<div
					className="w-screen relative z-0 aaasss"
				>
					<div className="w-40vw absolute right-0 top-96 mt-64 z-0">
						<img
							src="/static/svg/promo-bg-2.svg"
							alt="fitur diskon dan promo"
							style={{
								WebkitTransform: "scaleX(-1)",
								transform: "scaleX(-1)",
								filter: "blur(10px)",
								zIndex: 0
							}}
						/>
					</div>
				</div>
				<div className="container z-10 relative pt-40 lg:pt-48">
					<div className="mb-5 mt-md-0 mt-5 white-text text-center">
						<h1 className="h1-responsive text-3xl font-weight-bold wow fadeInLeft pb-8 lg:pb-0" data-wow-delay="0.3s">
							Dapatkan Katalog, Diskon dan Kupon<br/>terupdate dengan mudah!
						</h1>
						<SearchbarWithButton
							inputPlaceholder="Cari brand atau diskon disini. . ."
							btnText="Cari"
							classNameContainer={`h-12 rounded-3xl mt-3 mb-10 bg-white shadow-lg w-full lg:w-50vw mx-auto`}
							classNameInput="bg-transparent pl-3"
							classNameButton="bg-main-primary w-24 px-3 h-10 mr-2 text-white"
							inputValue={searchKeyword}
							inputChange={setSearchKeyword}
							onSubmit={onSearch}
						/>
						<p className="mb-3 wow fadeInLeft text-base mt-8" data-wow-delay="0.3s">
							Disqonin merupakan platform Diskon dan Promo terlengkap dan terupdate di Indonesia.<br/>
							Diskon dari ribuan brand dan puluhan kategori menunggumu! Download Sekarang!
						</p>
						<div className="flex justify-center">
							<a href="https://bit.ly/disqonin-android" target="_blank" rel="noreferrer">
								<LazyLoad height={60} once>
									<img
										alt="Get it on Google Play"
										style={{ height: 60 }}
										src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
									/>
								</LazyLoad>
							</a>
							<a href="https://bit.ly/disqonin-ios" target="_blank" rel="noreferrer">
								<LazyLoad height={60}>
									<img
										alt="Get it on App Store"
										style={{ height: 60 }}
										src={`/static/img/home/app-store-badge${isShowPng ? '.png' : '.webp'}`}
									/>
								</LazyLoad>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}

export default HomeLead
