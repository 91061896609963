import React from 'react';

export const SearchbarWithButton = ({
    btnText = '',
    inputValue = '',
    inputPlaceholder = '',
    inputChange = () => null,
    onSubmit = () => null,

    classNameContainer = '',
    classNameInput = 'max-w-full pl-3',
    classNameButton = 'bg-main-primary w-24 h-10 mr-2 text-white'
}) => {
    const onChange = (e) => {
      inputChange(e.target.value)
    }
    const onKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    }
    return (
    <div className={`flex flex-row justify-center items-center relative ${classNameContainer}`}>
        <input
          className={`flex-1 outline-none ${classNameInput}`}
          placeholder={inputPlaceholder}
          value={inputValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <button
            onClick={onSubmit}
            className={`rounded-3xl text-lg font-bold absolute right-1 ${classNameButton}`}
        >
          {btnText}
        </button>
      </div>
    )
}